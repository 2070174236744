import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  SimpleGrid,
  SimpleGridProps,
} from '@chakra-ui/react';

type Props = {
  name?: string;
  mail?: string;
  phone?: string;
  portrait?: {
    gatsbyImage: IGatsbyImageData;
  };
};

const ContactPerson = ({ name, mail, phone, portrait }: Props) => {
  const cleanPhoneArr = phone && phone.match(/[+0-9]/g);
  const cleanPhone = cleanPhoneArr ? cleanPhoneArr.join('') : '';
  return (
    <SimpleGrid {...gridProps}>
      <Box {...portraitContainer}>
        {portrait && (
          <GatsbyImage image={portrait.gatsbyImage} alt={name || ''} />
        )}
      </Box>
      <Flex {...textProps}>
        <Box>
          <strong>{name}</strong>
        </Box>
        <Box>{mail}</Box>
        <Box>{phone}</Box>
        <Box width="100%">
          {mail && (
            <Button
              as="a"
              variant="blackToOrange"
              href={`mailto:${mail}`}
              {...buttonProps}
            >
              E-Mail
            </Button>
          )}
          {cleanPhone && (
            <Button
              as="a"
              variant="blackToOrange"
              href={`tel:${cleanPhone}`}
              {...buttonProps}
            >
              Anrufen
            </Button>
          )}
        </Box>
      </Flex>
    </SimpleGrid>
  );
};

const buttonProps: ButtonProps = {
  marginTop: '24px',
  _notLast: {
    marginRight: { md: '40px' },
  },
};

const gridProps: SimpleGridProps = {
  columns: { md: 3 },
  columnGap: '40px',
  marginTop: '80px',
  marginBottom: '80px',
  alignItems: 'center',
  justifyContent: 'stretch',
};

const textProps: FlexProps = {
  width: '100%',
  fontSize: 'md',
  gridColumn: { md: 'span 2' },
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: { base: 'center', md: 'flex-start' },
};

const portraitContainer: BoxProps = {
  margin: { base: 'auto', md: '0' },
  width: { base: '180px', md: 'auto' },
  height: { base: '180px', md: 'auto' },
  borderRadius: 'full',
  overflow: 'hidden',
  transform: 'translate3d(0,0,0)',
};

export default ContactPerson;
