import {
  Box,
  BoxProps,
  Button,
  Container,
  Heading,
  HeadingProps,
  SimpleGrid,
  SimpleGridProps,
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import ContactPerson from '../components/contactPerson';
import HeroImage from '../components/heroImage';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { WpLinkType } from '../types/WpLinkType';

type Props = {
  data: {
    pageData: {
      title: string;
      template: {
        contactData: {
          heroImage: {
            gatsbyImage: IGatsbyImageData;
          };
          headline: string;
          text: string;
          adress: string;
          contactPerson: {
            name: string;
            mail: string;
            phone: string;
            portrait: {
              gatsbyImage: IGatsbyImageData;
            };
          };
          googleMapsLink: WpLinkType;
        };
      };
    };
    seoData: {
      seo: {
        metaDescription: string;
        metaKeywords: string;
      };
    };
  };
};

const Contact = ({ data: { pageData, seoData } }: Props) => {
  const heroImage =
    pageData?.template?.contactData?.heroImage?.gatsbyImage || null;
  const headline = pageData?.template?.contactData?.headline || null;
  const text = pageData?.template?.contactData?.text || null;
  const contactPerson = pageData?.template?.contactData?.contactPerson;
  const adress = pageData?.template?.contactData?.adress || null;
  const googleMaps = pageData?.template?.contactData?.googleMapsLink || null;
  return (
    <Layout invertedFooter={true}>
      <Seo {...seoData.seo} title={pageData.title} />
      {heroImage && <HeroImage alt={pageData.title} image={heroImage} />}
      <Container>
        <SimpleGrid {...gridProps}>
          {headline && (
            <Heading
              as="h1"
              dangerouslySetInnerHTML={{ __html: headline }}
              {...headlineProps}
            />
          )}
          <Box {...contentProps}>
            {text && (
              <Box dangerouslySetInnerHTML={{ __html: text }} {...textProps} />
            )}
            <ContactPerson {...contactPerson} />
            {adress && (
              <Box
                dangerouslySetInnerHTML={{ __html: adress }}
                {...adressProps}
              />
            )}
            {googleMaps && (
              <Button
                as="a"
                variant="blackToOrange"
                href={googleMaps.url}
                target={googleMaps.target}
                rel="noopener nofollow"
              >
                {googleMaps.title}
              </Button>
            )}
          </Box>
        </SimpleGrid>
      </Container>
    </Layout>
  );
};

const gridProps: SimpleGridProps = {
  marginTop: { base: '80px', md: '120px' },
  marginBottom: { base: '80px', md: '120px' },
  columns: { md: 12 },
  columnGap: '40px',
};
const headlineProps: HeadingProps = {
  gridColumn: { md: 'span 6' },
  fontSize: { base: '48px', md: '2xl' },
  lineHeight: 'shorter',
  marginBottom: { base: '24px', md: '0' },
};
const contentProps: BoxProps = {
  gridColumn: { md: '7 / span 6' },
};

const textProps: BoxProps = {
  fontSize: { base: 'lg', md: 'xl' },
};
const adressProps: BoxProps = {
  fontSize: 'xl',
  textAlign: { base: 'center', md: 'left' },
  marginTop: { base: '80px' },
  marginBottom: { base: '24px' },
};

export default Contact;

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    pageData: wpPage(id: { eq: $id }) {
      title
      template {
        ... on WpTemplate_Contact {
          contactData {
            heroImage {
              gatsbyImage(
                width: 1440
                height: 450
                layout: FULL_WIDTH
                fit: COVER
              )
            }
            headline
            text
            adress
            contactPerson {
              name
              mail
              phone
              portrait {
                gatsbyImage(
                  width: 180
                  height: 180
                  aspectRatio: 1
                  layout: FULL_WIDTH
                  fit: COVER
                )
              }
            }
            googleMapsLink {
              title
              url
              target
            }
          }
        }
      }
    }
    seoData: wpPage(id: { eq: $id }) {
      seo {
        metaDescription
        metaKeywords
      }
    }
  }
`;
